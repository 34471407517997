import React from "react";
import Products from "../../components/admin/pages/Products";

export default function ProductsPage() {
  return (
    <>
      <Products />
    </>
);
}
