import React from "react";
import Cart from "../../components/products/Cart";

export default function CartPage() {
  return (
    <div>
      <Cart />
    </div>
  );
}
