import React from "react";
import Layout from "../../components/products/Layout";

export default function products() {
  return (
    <>
      <Layout />
    </>
  );
}
