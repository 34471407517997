import React from "react";
import Pages from "../../components/admin/pages/Pages";

function PagesPage() {
  return (
    <>
      <Pages />
    </>
  );
}

export default PagesPage;
